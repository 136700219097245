import { client } from './axios';

const login = (body) => client.post(`/auth/login`, body);
const createUser = (body) => client.post(`/admin/user`, body);
const fetchUsers = (params) => client.get(`/admin/users`, { params });
const fetchTransactions = (params) => client.get(`/admin/transactions`, { params });
const addDeposit = (body) => client.post(`/admin/transactions/deposit`, body);
const fetchBanks = () => client.get(`/bank-config`);
const deleteBank = (id) => client.delete(`/bank-config/${id}`);
const addBank = (body) => client.post(`/bank-config`, body);
const updateBank = ({ id, ...body }) => client.put(`/bank-config/${id}`, body);
const updateUser = ({ id, ...body }) => client.put(`/admin/user/${id}`, body);
const approve = (body) => client.post(`/admin/transactions/withdraw/approve`, body);
const reject = (body) => client.post(`/admin/transactions/withdraw/reject`, body);
const fetchReportsGeneral = () => client.get(`/reports/general`);
const fetchReportsRevenue = (params) => client.get(`/reports/revenue`, { params });
const reset2FA = (id) => client.put(`/admin/user/${id}/reset-2fa`);
const updateSystemConfigs = (body) => client.put(`/admin/system-configs`, body);
const fetchSystemConfigs = () => client.get(`/admin/system-configs/info`);
const deleteUser = (id) => client.delete(`/admin/${id}`);

const adminService = {
  deleteUser,
  updateUser,
  login,
  fetchUsers,
  createUser,
  fetchTransactions,
  addDeposit,
  fetchBanks,
  deleteBank,
  addBank,
  updateBank,
  approve,
  reject,
  fetchReportsGeneral,
  fetchReportsRevenue,
  reset2FA,
  updateSystemConfigs,
  fetchSystemConfigs,
};

export default adminService;
